import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import { formatPrice } from "../../utils/format-price"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import variables from "../../styles/variables"

const CardContainer = styled.div`
  width: 100%;
  padding-bottom: 21px;

  @media (min-width: ${breakpoints.md}) {
    width: calc(50% - 20px);
    padding-bottom: 30px;
    margin-right: 20px;
  }

  img {
    z-index: 0 !important;
  }
`

const CardDetailsContainer = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
`

const Heading = styled.h2`
  font-size: ${variables.textSm};
  line-height: ${variables.lineHeightSm};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${variables.textS};
    line-height: ${variables.lineHeightS};
  }
`

const PriceText = styled.p`
  font-size: 14px;
  line-height: ${variables.lineHeightSm};
  padding-top: 6px;
  font-family: "Century Schoolbook", serif;
  font-style: italic;

  @media (min-width: ${breakpoints.md}) {
    font-size: 18px;
    line-height: ${variables.lineHeightS};
  }
`

const PurchaseLink = styled(Link)`
  font-size: ${variables.textSm};
  line-height: ${variables.lineHeightSm};
  border-width: 2px;
  padding-left: 16px;
  padding-right: 22px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: block;

  :hover {
    color: ${variables.colorWhiteBG};
    background-color: ${variables.colorBlackFG};
    border-color: ${variables.colorBlackFG};
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: ${variables.textS};
    line-height: ${variables.lineHeightS};
  }
`

const ProductCard = ({product}) => {
  const {
    handle,
    priceRangeV2,
    title,
    featuredImage,
    variants
  } = product

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    priceRangeV2.minVariantPrice.amount
  )

  return (
    <CardContainer>
      {featuredImage &&
      <Link to={`/product/${handle}`}>
        <GatsbyImage
        image={featuredImage.gatsbyImageData}
        alt={featuredImage.altText ? featuredImage.altText : `Product Image of ${title}`}
        />
      </Link>
      }
      <CardDetailsContainer>
        <div>
          <Heading>{title}</Heading>
          <PriceText>{variants && variants.length > 1 ? "From " : ""}{price}</PriceText>
        </div>
        <div>
          <PurchaseLink
            to={`/product/${handle}`}
          >
            Purchase
          </PurchaseLink>
        </div>
      </CardDetailsContainer>
    </CardContainer>
  )
}

export default ProductCard
