import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import CollectionSection from "../components/Index/CollectionSection"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"

const MobileLine = styled.div`
  border-top-width: 2px;
  height: 0;
  width: 100%;

  @media (min-width ${breakpoints.md}) {
    border-top-width: 0;
  }
`

const Line = styled.div`
  border-top-width: 2px;
  height: 0;
  width: 100%;
`

export default function IndexPage({data: {collections}, location}) {
  const path = location.pathname

  return (
    <Layout store={true} homepage={true}>
      <Seo
        fullTitle={false}
        path={path}
        title="Shop"
      />
      {collections.edges.map((collection, index) => {
        return (
          <React.Fragment key={index}>
            {index === 0 ? <MobileLine /> : <Line />}
            <CollectionSection collection={collection.node} />
          </ React.Fragment>
        )
      })}
    </Layout>
  )
}

export const indexPageQuery = graphql`
  query {
    collections: allShopifyCollection(sort: {fields: handle, order: DESC}) {
      edges {
        node {
          description
          title
          products {
            handle
            priceRangeV2 {
              minVariantPrice {
                amount
                currencyCode
              }
            }
            featuredImage {
              gatsbyImageData
            }
            title
            tags
            variants {
              title
            }
          }
        }
      }
    }
  }
`
