import * as React from "react"
import ProductCard from "./ProductCard"
import LoadMore from "./LoadMore"
import variables from "../../styles/variables"
import breakpoints from "../../styles/breakpoints"
import styled from "styled-components"

const CollectionHeading = styled.h2`
  margin-bottom: 28px;
  font-size: ${variables.textL};
  line-height: ${variables.lineHeightL};

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 36px;
    font-size: ${variables.textXL};
    line-height: ${variables.lineHeightXL};
  }
`

const TagHeading = styled.h3`
  margin-bottom: 14px;
  font-size: ${variables.textSm};
  line-height: ${variables.lineHeightSm};

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 32px;
    font-size: ${variables.textXL};
    line-height: ${variables.lineHeightXL};
  }
`

const Text = styled.p`
  font-size: ${variables.textSm};
  line-height: ${variables.lineHeightSm};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${variables.textS};
    line-height: ${variables.lineHeightS};
  }
`

const FilterText = styled.p`
  font-size: ${variables.textSm};
  line-height: ${variables.lineHeightSm};
  margin-top: 20px;
  margin-bottom: 38px;

  @media (min-width: ${breakpoints.md}) {
    font-size: ${variables.textS};
    line-height: ${variables.lineHeightS};
    margin-top: 28px;
    margin-bottom: 0;
  }
`

const FilterButtonSpan = styled.span`
  font-size: ${variables.textSm};
  line-height: ${variables.lineHeightSm};
  border-bottom: ${props => props.active ? `2px solid ${variables.colorBlackFG}` : "none"};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${variables.textS};
    line-height: ${variables.lineHeightS};
  }
`

const CollectionInfoSection = styled.div`
  width: 100%;
  padding-right: calc(44px + ${variables.pagePaddingMobile});
  padding-left: ${variables.pagePaddingMobile};
  padding-top: 18px;

  @media (min-width: ${breakpoints.md}) {
    padding-right: calc(44px + ${variables.pagePaddingDesktop});
    padding-left: ${variables.pagePaddingDesktop};
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 34%;
    padding-left: ${variables.pagePaddingDesktop};
    padding-right: 100px;
  }
`

const ProductCardSection = styled.div`
  width: 100%;
  padding-bottom: 30px;
  padding-left: ${variables.pagePaddingMobile};
  padding-right: ${variables.pagePaddingMobile};

  @media (min-width: ${breakpoints.md}) {
    padding-left: ${variables.pagePaddingDesktop};
    padding-right: 0;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 66%;
    padding-left: 0;
    padding-right: 0;
  }
`

const ProductCardSubsection = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;

  border-top-width: 1px;

  display: ${props => props.hide ? "none !important" : "block"};

  @media (min-width: ${breakpoints.md}) {
    padding-right: calc(${variables.pagePaddingDesktop} - 20px);
    padding-top: 18px;
    padding-bottom: 30px;

    border-top-width: ${props => props.first === 0 ? "0px" : "1px"};

    display: ${props => props.hide ? "block !important" : "block"};
  }
`

const ProductCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const LoadMoreSection = styled.div`
  display: block;
  padding: 10px 14px;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const CollectionSection = ({collection}) => {
  const {
    description,
    title,
    products
  } = collection

  const [loadMore, setLoadMore] = React.useState(1)

  const handleLoadMoreClick = () => {
    setLoadMore(loadMore + 1)
  }

  const getAllTags = () => {
    const tags = new Set([])
    for (const product of products) {
      for (const tag of product.tags) {
        tags.add(tag)
      }
    }
    tags.delete('Book')
    tags.delete('Print')
    return Array.from(tags)
  }

  const tags = getAllTags()

  const [displayTags, setDisplayTags] = React.useState(tags)
  const [activeTag, setActiveTag] = React.useState("All")

  
  const filteredTags = tags.filter((tag) => displayTags.includes(tag))

  return (
    <>
      <CollectionInfoSection>
        <CollectionHeading>{title}</CollectionHeading>
        <Text>{description}</Text>
        <FilterText>Filter:&nbsp;
          <button 
            onClick={() => {
              setDisplayTags(tags)
              setActiveTag("All")
            }} 
          >
            <FilterButtonSpan active={activeTag === "All" ? true : false}>All</FilterButtonSpan>,&nbsp;
          </button>
          {tags.map((tag, index) => {
            return (
              <button
                onClick={() => {
                  setDisplayTags([tag])
                  setActiveTag(tag)
                  setLoadMore(1)
                }} 
                key={index} 
              >
                <FilterButtonSpan active={activeTag === tag ? true : false}>{tag}</FilterButtonSpan>{index !== tags.length - 1 && ",\u00A0"}
              </button>
            )
          })}
        </FilterText>
      </CollectionInfoSection>
      <ProductCardSection>
        {
          filteredTags.map((tag, index) => {
            return (
              <ProductCardSubsection key={index} first={index} hide={filteredTags.length !== 0 && loadMore < (index + 1) ? true : false}>
                <TagHeading>{tag}</TagHeading>
                <ProductCardContainer>
                  {products.map((product, index) => {
                    if (product.tags.includes(tag)) {
                      return (
                        <ProductCard product={product} key={index}/>
                      )
                    }
                  })}
                </ProductCardContainer>
              </ProductCardSubsection>
            )
          })
        }
        {filteredTags.length > loadMore && (
          <LoadMoreSection>
            <LoadMore handleLoadMoreClick={handleLoadMoreClick} />
          </LoadMoreSection>
        )}
      </ProductCardSection>
    </>
  )
}

export default CollectionSection
